import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import ReactPlayer from 'react-player';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in";

const DigitalProjects = ({ data }) => {
  const [currentDigitalProjects, setCurrentDigitalProjects] = useState([]);
  const [pastDigitalProjects, setPastDigitalProjects] = useState([]);
  const { digitalprojects, singleDigitalProject } = data;
  const featuredSideBarText = 'Latest projects';
  const allSideBarText = 'All projects';

  function handleDigitalProjectData(projectData) {
    // feature two most recent projects (sort query desc by publish date)
    const projects = projectData.map((project) => project.node);
    setCurrentDigitalProjects(projects.slice(0, 2));
    setPastDigitalProjects(projects.slice(2, projects.length));
  }

  useEffect(() => {
    handleDigitalProjectData(singleDigitalProject.edges);
  }, [singleDigitalProject.edges]);

  return (
    <Layout bgcolor={digitalprojects.backgroundColor} wrapperClass="digital-projects" accentColor={digitalprojects.accentColor}>
      <HelmetDatoCms seo={digitalprojects.seoMetaTags} />
      <div className="digital-projects-title container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{digitalprojects.title}</h1>
        </div>
      </div>
      {currentDigitalProjects.length > 0 && (
        <div className="digital-projects-latest container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">{featuredSideBarText}</span>
          </div>
          <div className="latest-projects row col spacing-0 col-sm-12">
            {currentDigitalProjects.map((program) => (
              <div key={program.id} className="digital-program-tile col col-xs-5">
                <DividerBlock accentColor={digitalprojects.accentColor} />
                <Link className="program-link-wrap" to={`${program.slug}`}>
                  {program.featuredImage.video ? (
                    <ReactPlayer
                      url={program.featuredImage.video.mp4Url}
                      width="100%"
                      height="60%"
                      playing
                      muted
                      loop
                      playsinline={true}
                      controls={false}
                      className="responsive-iframe"
                    />
                  ) : (
										<ImageFadeIn
											src={program.featuredImage.url}
											alt={program.featuredImage.alt}
											className="program-featured-image"
										/>
                  )}
                  <div className="tile-text-wrap">
                    <span
                      className="project-name"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: program.formattedTitle,
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="section-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
            <DividerBlock accentColor={digitalprojects.accentColor} />
          </div>
        </div>
      )}
      {pastDigitalProjects.length > 0 && (
        <div className="digital-projects-all container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">{allSideBarText}</span>
          </div>
          <div className="tiles tiles-3 previous-projects col-sm-12 col-xs-6 row col spacing-0">
            {pastDigitalProjects.map((program) => (
              <Link to={`${program.slug}`} key={program.id} className="tile col col-xs-3">
                {program.featuredImage.video ? (
                  <ReactPlayer
                    url={program.featuredImage.video.mp4Url}
                    width="100%"
                    height="60%"
                    playing
                    muted
                    loop
                    controls={false}
                    className="responsive-iframe"
                  />
                ) : (
									<ImageFadeIn
										src={program.featuredImage.url}
										alt={program.featuredImage.alt}
										className="program-featured-image"
									/>
                )}
                <div className="tile-text-wrap">
                  <h3
                    className="project-name"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: program.formattedTitle,
                    }}
                  />
                </div>
              </Link>
            ))}
          </div>
          <div className="section-divider col-sm-5 col-xs-4 offset-xs-2 offset-sm-8 col">
            <DividerBlock accentColor={digitalprojects.accentColor} />
          </div>
        </div>
      )}
    </Layout>
  );
};

DigitalProjects.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default DigitalProjects;

export const query = graphql`
  query DigitalProjectsQuery {
    digitalprojects: datoCmsDigitalProject {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      accentColor {
        hex
      }
      backgroundColor
      title
    }
    singleDigitalProject: allDatoCmsSingleDigitalProject(
      sort: {
        fields: [originalPublishDate],
        order: DESC
      },
      filter: {meta: {isValid: {eq: true}, status: {ne: "draft"}}}
    ) {
      edges {
        node {
          id
          formattedTitle
            slug
            featuredImage {
              alt
							url(imgixParams: { w: "800", ar: "5:3", fit: "crop", crop: "focalpoint" })
              video {
                mp4Url
              }
            }
          }
        }
      }
    }
`;
